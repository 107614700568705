/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export { Api } from './Api';

export { ApiError } from './core/ApiError';
export { BaseHttpRequest } from './core/BaseHttpRequest';
export { CancelablePromise, CancelError } from './core/CancelablePromise';
export { OpenAPI } from './core/OpenAPI';
export type { OpenAPIConfig } from './core/OpenAPI';

export type { AcceptDailyBonusRequest } from './models/AcceptDailyBonusRequest';
export type { AcceptDailyBonusResponse } from './models/AcceptDailyBonusResponse';
export type { AcceptFreeGoldCoinsRequest } from './models/AcceptFreeGoldCoinsRequest';
export type { AcceptFreeGoldCoinsResponse } from './models/AcceptFreeGoldCoinsResponse';
export type { AcceptInvitationBonusRequest } from './models/AcceptInvitationBonusRequest';
export type { AcceptInvitationBonusResponse } from './models/AcceptInvitationBonusResponse';
export type { AcceptManualBonusRequest } from './models/AcceptManualBonusRequest';
export type { AcceptManualBonusResponse } from './models/AcceptManualBonusResponse';
export type { AcceptPersonalJackpotRequest } from './models/AcceptPersonalJackpotRequest';
export type { AcceptPersonalJackpotResponse } from './models/AcceptPersonalJackpotResponse';
export type { AcceptRulesRequest } from './models/AcceptRulesRequest';
export type { AcceptRulesResponse } from './models/AcceptRulesResponse';
export type { AcceptSocialMediaRewardRequest } from './models/AcceptSocialMediaRewardRequest';
export type { AcceptSocialMediaRewardResponse } from './models/AcceptSocialMediaRewardResponse';
export type { AcceptSweepstakeIntroRequest } from './models/AcceptSweepstakeIntroRequest';
export type { AcceptSweepstakeIntroResponse } from './models/AcceptSweepstakeIntroResponse';
export type { AccountBalanceInfo } from './models/AccountBalanceInfo';
export type { AccountClosureRequest } from './models/AccountClosureRequest';
export type { AccountClosureResponse } from './models/AccountClosureResponse';
export { AccountInfo } from './models/AccountInfo';
export type { AccountInvitations } from './models/AccountInvitations';
export type { AccountJackpotInfo } from './models/AccountJackpotInfo';
export type { AccountJackpotWin } from './models/AccountJackpotWin';
export type { AccountNotificationInfo } from './models/AccountNotificationInfo';
export type { AccountPersonalJackpotWin } from './models/AccountPersonalJackpotWin';
export type { AccountRestrictions } from './models/AccountRestrictions';
export type { AccountSessionLimit } from './models/AccountSessionLimit';
export type { AccountSessionLimitsInfo } from './models/AccountSessionLimitsInfo';
export { AccountSessionRestriction } from './models/AccountSessionRestriction';
export { AccountsMode } from './models/AccountsMode';
export type { ActivateAccountFreeSpinRequest } from './models/ActivateAccountFreeSpinRequest';
export { ActivateAccountFreeSpinResponse } from './models/ActivateAccountFreeSpinResponse';
export type { AddAccountFavouriteGameRequest } from './models/AddAccountFavouriteGameRequest';
export type { AddAccountFavouriteGameResponse } from './models/AddAccountFavouriteGameResponse';
export type { AppliedRules } from './models/AppliedRules';
export type { appName } from './models/appName';
export type { BannerInfo } from './models/BannerInfo';
export type { brandName } from './models/brandName';
export type { CancelGiveawayRequest } from './models/CancelGiveawayRequest';
export { CancelGiveawayResponse } from './models/CancelGiveawayResponse';
export type { CashbackBonusInfo } from './models/CashbackBonusInfo';
export type { ChangePasswordRequest } from './models/ChangePasswordRequest';
export type { ChangePasswordResponse } from './models/ChangePasswordResponse';
export type { ChangeSubscriptionPreferencesRequest } from './models/ChangeSubscriptionPreferencesRequest';
export type { ChangeSubscriptionPreferencesResponse } from './models/ChangeSubscriptionPreferencesResponse';
export type { CheckLocationStatusRequest } from './models/CheckLocationStatusRequest';
export type { CheckLocationStatusResponse } from './models/CheckLocationStatusResponse';
export type { CheckPasswordRequest } from './models/CheckPasswordRequest';
export type { CheckPasswordResponse } from './models/CheckPasswordResponse';
export type { ClaimMissionFreeSpinRewardsRequest } from './models/ClaimMissionFreeSpinRewardsRequest';
export type { ClaimMissionFreeSpinRewardsResponse } from './models/ClaimMissionFreeSpinRewardsResponse';
export type { CoinGiveawayPrizeConfig } from './models/CoinGiveawayPrizeConfig';
export type { CompleteMiniGameRequest } from './models/CompleteMiniGameRequest';
export type { CompleteMiniGameResponse } from './models/CompleteMiniGameResponse';
export type { CountryAndStates } from './models/CountryAndStates';
export type { CreateAccountSessionLimitRequest } from './models/CreateAccountSessionLimitRequest';
export type { CreateAccountSessionLimitResponse } from './models/CreateAccountSessionLimitResponse';
export { CreateAccountSessionRestrictionRequest } from './models/CreateAccountSessionRestrictionRequest';
export type { CreateAccountSessionRestrictionResponse } from './models/CreateAccountSessionRestrictionResponse';
export type { CreateCoinGiveawayRequest } from './models/CreateCoinGiveawayRequest';
export { CreateCoinGiveawayResponse } from './models/CreateCoinGiveawayResponse';
export type { CreateFreeSpinGiveawayRequest } from './models/CreateFreeSpinGiveawayRequest';
export { CreateFreeSpinGiveawayResponse } from './models/CreateFreeSpinGiveawayResponse';
export type { CreateGiveawayRequest } from './models/CreateGiveawayRequest';
export { CreateGiveawayResponse } from './models/CreateGiveawayResponse';
export type { CreatePostalCodeRequest } from './models/CreatePostalCodeRequest';
export type { CreatePostalCodeResponse } from './models/CreatePostalCodeResponse';
export { Currency } from './models/Currency';
export { CurrencyPolicies } from './models/CurrencyPolicies';
export type { DailyBonusInfo } from './models/DailyBonusInfo';
export type { DeleteAccountRequest } from './models/DeleteAccountRequest';
export type { DeleteAccountResponse } from './models/DeleteAccountResponse';
export type { DeleteActiveAccountSessionLimitRequest } from './models/DeleteActiveAccountSessionLimitRequest';
export type { DeleteActiveAccountSessionLimitResponse } from './models/DeleteActiveAccountSessionLimitResponse';
export type { DoNotAllowGpsCoordinatesEventRequest } from './models/DoNotAllowGpsCoordinatesEventRequest';
export type { DoNotAllowGpsCoordinatesEventResponse } from './models/DoNotAllowGpsCoordinatesEventResponse';
export type { ErrorResponse } from './models/ErrorResponse';
export type { FinalizeCoinGiveawayRequest } from './models/FinalizeCoinGiveawayRequest';
export { FinalizeCoinGiveawayResponse } from './models/FinalizeCoinGiveawayResponse';
export type { FinalizeFreeSpinGiveawayRequest } from './models/FinalizeFreeSpinGiveawayRequest';
export { FinalizeFreeSpinGiveawayResponse } from './models/FinalizeFreeSpinGiveawayResponse';
export type { FinalizeGiveawayRequest } from './models/FinalizeGiveawayRequest';
export { FinalizeGiveawayResponse } from './models/FinalizeGiveawayResponse';
export type { FreeSpinGiveawayPrizeConfig } from './models/FreeSpinGiveawayPrizeConfig';
export type { FreeSpinInfo } from './models/FreeSpinInfo';
export type { FreeSpinsPrizeInfo } from './models/FreeSpinsPrizeInfo';
export { GameEventRequest } from './models/GameEventRequest';
export type { GameEventResponse } from './models/GameEventResponse';
export type { GameLaunchRequest } from './models/GameLaunchRequest';
export type { GameLaunchResponse } from './models/GameLaunchResponse';
export type { GameType } from './models/GameType';
export { GameTypes } from './models/GameTypes';
export type { GetAccountBonusesRequest } from './models/GetAccountBonusesRequest';
export type { GetAccountBonusesResponse } from './models/GetAccountBonusesResponse';
export type { GetAccountFreeSpinsRequest } from './models/GetAccountFreeSpinsRequest';
export type { GetAccountFreeSpinsResponse } from './models/GetAccountFreeSpinsResponse';
export type { GetAccountInfoRequest } from './models/GetAccountInfoRequest';
export { GetAccountInfoResponse } from './models/GetAccountInfoResponse';
export type { GetAccountInvitationsRequest } from './models/GetAccountInvitationsRequest';
export type { GetAccountInvitationsResponse } from './models/GetAccountInvitationsResponse';
export type { GetAccountJackpotInfoRequest } from './models/GetAccountJackpotInfoRequest';
export type { GetAccountJackpotInfoResponse } from './models/GetAccountJackpotInfoResponse';
export type { GetAccountMetaRequest } from './models/GetAccountMetaRequest';
export type { GetAccountMetaResponse } from './models/GetAccountMetaResponse';
export type { GetAccountNotificationsRequest } from './models/GetAccountNotificationsRequest';
export type { GetAccountNotificationsResponse } from './models/GetAccountNotificationsResponse';
export type { GetAccountRefererInfoRequest } from './models/GetAccountRefererInfoRequest';
export type { GetAccountRefererInfoResponse } from './models/GetAccountRefererInfoResponse';
export type { GetAccountSessionLimitsRequest } from './models/GetAccountSessionLimitsRequest';
export type { GetAccountSessionLimitsResponse } from './models/GetAccountSessionLimitsResponse';
export type { GetAccountSessionRestrictionsRequest } from './models/GetAccountSessionRestrictionsRequest';
export type { GetAccountSessionRestrictionsResponse } from './models/GetAccountSessionRestrictionsResponse';
export type { GetAccountSettingsRequest } from './models/GetAccountSettingsRequest';
export type { GetAccountSettingsResponse } from './models/GetAccountSettingsResponse';
export type { GetActiveGiveawayByAccountRequest } from './models/GetActiveGiveawayByAccountRequest';
export type { GetActiveGiveawayByAccountResponse } from './models/GetActiveGiveawayByAccountResponse';
export type { GetActiveGiveawayRequest } from './models/GetActiveGiveawayRequest';
export type { GetActiveGiveawayResponse } from './models/GetActiveGiveawayResponse';
export type { GetBannersRequest } from './models/GetBannersRequest';
export type { GetBannersResponse } from './models/GetBannersResponse';
export type { GetCurrencyAvailabilityRequest } from './models/GetCurrencyAvailabilityRequest';
export type { GetCurrencyAvailabilityResponse } from './models/GetCurrencyAvailabilityResponse';
export type { GetFreeGoldCoinsStatusRequest } from './models/GetFreeGoldCoinsStatusRequest';
export type { GetFreeGoldCoinsStatusResponse } from './models/GetFreeGoldCoinsStatusResponse';
export type { GetGiveawayRequest } from './models/GetGiveawayRequest';
export type { GetGiveawayResponse } from './models/GetGiveawayResponse';
export type { GetGiveawaySubscribersNumberRequest } from './models/GetGiveawaySubscribersNumberRequest';
export type { GetGiveawaySubscribersNumberResponse } from './models/GetGiveawaySubscribersNumberResponse';
export type { GetInfluencerInfoRequest } from './models/GetInfluencerInfoRequest';
export type { GetInfluencerInfoResponse } from './models/GetInfluencerInfoResponse';
export type { GetJackpotInfoRequest } from './models/GetJackpotInfoRequest';
export type { GetJackpotInfoResponse } from './models/GetJackpotInfoResponse';
export type { GetJackpotOptInRequest } from './models/GetJackpotOptInRequest';
export type { GetJackpotOptInResponse } from './models/GetJackpotOptInResponse';
export type { GetJackpotTotalsRequest } from './models/GetJackpotTotalsRequest';
export type { GetJackpotTotalsResponse } from './models/GetJackpotTotalsResponse';
export type { GetLotteryResultsRequest } from './models/GetLotteryResultsRequest';
export type { GetLotteryResultsResponse } from './models/GetLotteryResultsResponse';
export type { GetPersonalJackpotRequest } from './models/GetPersonalJackpotRequest';
export type { GetPersonalJackpotResponse } from './models/GetPersonalJackpotResponse';
export type { GetRandomRewardTemplatesRequest } from './models/GetRandomRewardTemplatesRequest';
export type { GetRandomRewardTemplatesResponse } from './models/GetRandomRewardTemplatesResponse';
export type { GetRewardTemplateRequest } from './models/GetRewardTemplateRequest';
export type { GetSignupCountriesAndStatesRequest } from './models/GetSignupCountriesAndStatesRequest';
export type { GetSignupCountriesAndStatesResponse } from './models/GetSignupCountriesAndStatesResponse';
export type { GetSignupCountriesRequest } from './models/GetSignupCountriesRequest';
export type { GetSignupCountriesResponse } from './models/GetSignupCountriesResponse';
export type { GetTemporaryTokenRequest } from './models/GetTemporaryTokenRequest';
export { GetTemporaryTokenResponse } from './models/GetTemporaryTokenResponse';
export type { GetVisitedPromotionsRequest } from './models/GetVisitedPromotionsRequest';
export type { GetVisitedPromotionsResponse } from './models/GetVisitedPromotionsResponse';
export { Giveaway } from './models/Giveaway';
export type { GiveawayPrize } from './models/GiveawayPrize';
export type { GiveawayRequestorInfo } from './models/GiveawayRequestorInfo';
export type { GoldCoinGeneratorSettings } from './models/GoldCoinGeneratorSettings';
export type { Influencer } from './models/Influencer';
export type { InGameJackpot } from './models/InGameJackpot';
export type { InGameJackpotsResponse } from './models/InGameJackpotsResponse';
export type { InGameJackpotWinner } from './models/InGameJackpotWinner';
export type { InGameJackpotWinnersResponse } from './models/InGameJackpotWinnersResponse';
export type { InitialMissionAssignmentRequest } from './models/InitialMissionAssignmentRequest';
export type { InitialMissionAssignmentResponse } from './models/InitialMissionAssignmentResponse';
export type { InvitationBonusInfo } from './models/InvitationBonusInfo';
export type { InvitationInfo } from './models/InvitationInfo';
export type { InvitationRewardPolicy } from './models/InvitationRewardPolicy';
export type { JackpotAccountFreeContribution } from './models/JackpotAccountFreeContribution';
export { JackpotInfo } from './models/JackpotInfo';
export type { JackpotPreferences } from './models/JackpotPreferences';
export type { JackpotWinnerInfo } from './models/JackpotWinnerInfo';
export type { JsonNode } from './models/JsonNode';
export type { KYCInfo } from './models/KYCInfo';
export { KYCStatus } from './models/KYCStatus';
export type { LegalRule } from './models/LegalRule';
export { LegalRuleSettings } from './models/LegalRuleSettings';
export { LevelInfo } from './models/LevelInfo';
export type { Limit } from './models/Limit';
export type { ListMissionsRequest } from './models/ListMissionsRequest';
export type { ListMissionsResponse } from './models/ListMissionsResponse';
export type { LocationInfo } from './models/LocationInfo';
export type { LowOnCoinsRequest } from './models/LowOnCoinsRequest';
export type { LowOnCoinsResponse } from './models/LowOnCoinsResponse';
export type { ManualBonusInfo } from './models/ManualBonusInfo';
export type { MiniGameRewardsInfo } from './models/MiniGameRewardsInfo';
export type { MissionInfo } from './models/MissionInfo';
export type { MissionRewardInfo } from './models/MissionRewardInfo';
export type { MissionStepInfo } from './models/MissionStepInfo';
export type { MissionStepUpdateRequest } from './models/MissionStepUpdateRequest';
export type { MissionStepUpdateResponse } from './models/MissionStepUpdateResponse';
export type { ObfuscatedString } from './models/ObfuscatedString';
export type { OptInGiveawayRequest } from './models/OptInGiveawayRequest';
export { OptInGiveawayResponse } from './models/OptInGiveawayResponse';
export type { OptInPromoRequest } from './models/OptInPromoRequest';
export type { OptInPromoResponse } from './models/OptInPromoResponse';
export type { OptOutArbitrationRequest } from './models/OptOutArbitrationRequest';
export { OptOutArbitrationResponse } from './models/OptOutArbitrationResponse';
export type { PasswordEnforcementPolicy } from './models/PasswordEnforcementPolicy';
export type { Permissions } from './models/Permissions';
export type { PersonalInfo } from './models/PersonalInfo';
export type { PlacementResponse } from './models/PlacementResponse';
export type { PlacementType } from './models/PlacementType';
export type { platform } from './models/platform';
export type { PrizeTable } from './models/PrizeTable';
export type { PrizeTableItem } from './models/PrizeTableItem';
export type { PrizeTablePrizeItem } from './models/PrizeTablePrizeItem';
export type { PrizeTablePrizeItemData } from './models/PrizeTablePrizeItemData';
export { ProductInfo } from './models/ProductInfo';
export type { ProductInfoTags } from './models/ProductInfoTags';
export { ProductModes } from './models/ProductModes';
export { Promo } from './models/Promo';
export type { PurchaseOfferInfo } from './models/PurchaseOfferInfo';
export type { qAppName } from './models/qAppName';
export type { qBrandName } from './models/qBrandName';
export { qPlatform } from './models/qPlatform';
export type { RandomRewardTemplateDto } from './models/RandomRewardTemplateDto';
export type { RedeemMoneyPolicy } from './models/RedeemMoneyPolicy';
export type { RemoveAccountFavouriteGameRequest } from './models/RemoveAccountFavouriteGameRequest';
export type { RemoveAccountFavouriteGameResponse } from './models/RemoveAccountFavouriteGameResponse';
export type { ResendEmailVerificationRequest } from './models/ResendEmailVerificationRequest';
export type { ResendEmailVerificationResponse } from './models/ResendEmailVerificationResponse';
export type { ResetPasswordManuallyRequest } from './models/ResetPasswordManuallyRequest';
export type { ResetPasswordManuallyResponse } from './models/ResetPasswordManuallyResponse';
export type { ResetPasswordRequest } from './models/ResetPasswordRequest';
export type { ResetPasswordResponse } from './models/ResetPasswordResponse';
export { RuleType } from './models/RuleType';
export type { SaveJackpotOptInRequest } from './models/SaveJackpotOptInRequest';
export type { SaveJackpotOptInResponse } from './models/SaveJackpotOptInResponse';
export type { SendSignInByTokenEmailRequest } from './models/SendSignInByTokenEmailRequest';
export type { SendSignInByTokenEmailResponse } from './models/SendSignInByTokenEmailResponse';
export type { SendWelcomeEmailRequest } from './models/SendWelcomeEmailRequest';
export type { SendWelcomeEmailResponse } from './models/SendWelcomeEmailResponse';
export type { SetAccountAttributionInfoRequest } from './models/SetAccountAttributionInfoRequest';
export type { SetAccountAttributionInfoResponse } from './models/SetAccountAttributionInfoResponse';
export type { SetAccountCommunicationPreferencesRequest } from './models/SetAccountCommunicationPreferencesRequest';
export type { SetAccountCommunicationPreferencesResponse } from './models/SetAccountCommunicationPreferencesResponse';
export type { SetAccountInfoRequest } from './models/SetAccountInfoRequest';
export { SetAccountInfoResponse } from './models/SetAccountInfoResponse';
export type { SetAccountMarketingInfoRequest } from './models/SetAccountMarketingInfoRequest';
export type { SetAccountMarketingInfoResponse } from './models/SetAccountMarketingInfoResponse';
export type { SetAccountModeRequest } from './models/SetAccountModeRequest';
export type { SetAccountModeResponse } from './models/SetAccountModeResponse';
export type { SetAccountOnboardedFlagRequest } from './models/SetAccountOnboardedFlagRequest';
export type { SetAccountOnboardedFlagResponse } from './models/SetAccountOnboardedFlagResponse';
export type { SetLocationInfoRequest } from './models/SetLocationInfoRequest';
export type { SetLocationInfoResponse } from './models/SetLocationInfoResponse';
export type { SetPushInfoRequest } from './models/SetPushInfoRequest';
export type { SetPushInfoResponse } from './models/SetPushInfoResponse';
export type { SignInManualRequest } from './models/SignInManualRequest';
export type { SignInPermanentRequest } from './models/SignInPermanentRequest';
export type { SignInResponse } from './models/SignInResponse';
export type { SignInSocialRequest } from './models/SignInSocialRequest';
export type { SignOutRequest } from './models/SignOutRequest';
export type { SignOutResponse } from './models/SignOutResponse';
export type { SignUpManualRequest } from './models/SignUpManualRequest';
export type { SignUpResponse } from './models/SignUpResponse';
export type { SignUpSocialRequest } from './models/SignUpSocialRequest';
export type { SignWithGuestRequest } from './models/SignWithGuestRequest';
export type { SpinWheelOfWinnersRequest } from './models/SpinWheelOfWinnersRequest';
export type { SpinWheelOfWinnersResponse } from './models/SpinWheelOfWinnersResponse';
export type { UpdateAccountNotificationStatusRequest } from './models/UpdateAccountNotificationStatusRequest';
export type { UpdateAccountNotificationStatusResponse } from './models/UpdateAccountNotificationStatusResponse';
export type { UpdateAccountSessionLimitRequest } from './models/UpdateAccountSessionLimitRequest';
export type { UpdateAccountSessionLimitResponse } from './models/UpdateAccountSessionLimitResponse';
export type { UpdateLocationStatusRequest } from './models/UpdateLocationStatusRequest';
export { UpdateLocationStatusResponse } from './models/UpdateLocationStatusResponse';
export type { UpdateVisitedPromotionsRequest } from './models/UpdateVisitedPromotionsRequest';
export type { UpdateVisitedPromotionsResponse } from './models/UpdateVisitedPromotionsResponse';
export type { WheelOfWinnersInfo } from './models/WheelOfWinnersInfo';
export type { WheelOfWinnersSection } from './models/WheelOfWinnersSection';
export type { X_Chk } from './models/X_Chk';
export type { X_Client_Coordinates } from './models/X_Client_Coordinates';
export type { X_Message_Id } from './models/X_Message_Id';
export type { X_Timestamp } from './models/X_Timestamp';
export type { xCoordinates } from './models/xCoordinates';
export type { xMessageId } from './models/xMessageId';
export { xStatus } from './models/xStatus';
export type { xStatusText } from './models/xStatusText';
export type { xTook } from './models/xTook';

export { AccountApiService } from './services/AccountApiService';
export { AuthApiService } from './services/AuthApiService';
export { CrmService } from './services/CrmService';
export { EmailService } from './services/EmailService';
export { GameHubService } from './services/GameHubService';
export { HomepageFeaturesApiService } from './services/HomepageFeaturesApiService';
export { JackpotsService } from './services/JackpotsService';
export { LaunchGameService } from './services/LaunchGameService';
export { MissionFreeSpinsService } from './services/MissionFreeSpinsService';
export { MissionsApiService } from './services/MissionsApiService';
export { NotificationService } from './services/NotificationService';
export { RandomRewardService } from './services/RandomRewardService';
