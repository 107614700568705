/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Returns updated KYC info
 */
export type SetKYCInfoResponseBody = {
    nextKYC: string;
    transactionReference: string;
    redirectUrl?: string;
    scanReference?: string;
    status?: SetKYCInfoResponseBody.status;
    errorReason?: string;
};
export namespace SetKYCInfoResponseBody {
    export enum status {
        SUCCESS = 'success',
        ERROR = 'error',
    }
}

