/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OfferInfo } from './OfferInfo';
import type { OrderError } from './OrderError';
import type { OrderStatus } from './OrderStatus';
import type { PurchaseMethod } from './PurchaseMethod';
export type CreateOrderResponseBody = {
    at?: string;
    clientToken?: string;
    /**
     * Used for direct fiserv apple pay only.
     */
    completeMerchantValidation?: string;
    /**
     * Echo back request `cvvEntered` parameter.
     */
    cvvEntered?: boolean;
    error?: OrderError;
    limitAmount?: string;
    limitAvailable?: string;
    limitEnd?: string;
    limitPeriod?: string;
    orderCode?: string;
    paymentData?: string;
    publicKeyBase64?: string;
    /**
     * URL to redirect. Applicable to integrations where redirect to provider page is needed.
     */
    redirectUrl?: string;
    requestKyc?: boolean;
    secure3D?: boolean;
    sourceId?: string;
    status?: string;
    supplier?: string;
    tempToken?: string;
    transactionId?: string;
    type?: string;
    offer?: OfferInfo;
    /**
     * Use price from offer (offer.price)
     * @deprecated
     */
    price?: string;
    billingDescriptor?: string;
    isFirstDeposit?: boolean;
    firstDepositDate?: string;
    city?: string;
    zip?: string;
    amount?: number;
    currency?: string;
    provider?: string;
    internalStatus?: OrderStatus;
    threeDsAuthorizeData?: {
        /**
         * SCA authenticate API call result.
         *
         */
        authenticateTxId?: string;
        /**
         * SCA authentication state. If 'pending' user should complete 3ds challenge.
         *
         */
        state?: CreateOrderResponseBody.state;
    };
    description?: string;
    method?: PurchaseMethod;
};
export namespace CreateOrderResponseBody {
    /**
     * SCA authentication state. If 'pending' user should complete 3ds challenge.
     *
     */
    export enum state {
        PENDING = 'pending',
        SUCCEEDED = 'succeeded',
    }
}

