/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompleteMiniGameRequest } from '../models/CompleteMiniGameRequest';
import type { CompleteMiniGameResponse } from '../models/CompleteMiniGameResponse';
import type { GetRandomRewardTemplatesRequest } from '../models/GetRandomRewardTemplatesRequest';
import type { GetRandomRewardTemplatesResponse } from '../models/GetRandomRewardTemplatesResponse';
import type { GetRewardTemplateRequest } from '../models/GetRewardTemplateRequest';
import type { MiniGameRewardsInfo } from '../models/MiniGameRewardsInfo';
import type { RandomRewardTemplateDto } from '../models/RandomRewardTemplateDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class RandomRewardService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Complete mini-game and take reward
     * @param brandName
     * @param requestBody
     * @returns CompleteMiniGameResponse Complete mini-game and take reward
     * @throws ApiError
     */
    public completeMiniGameReward(
        brandName: string,
        requestBody: CompleteMiniGameRequest,
    ): CancelablePromise<CompleteMiniGameResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/CompleteMiniGameReward',
            query: {
                'brandName': brandName,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }
    /**
     * Get mini-game reward instances
     * @param brandName
     * @returns MiniGameRewardsInfo Get mini-game reward instances
     * @throws ApiError
     */
    public getMiniGameRewards(
        brandName: string,
    ): CancelablePromise<MiniGameRewardsInfo> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/GetMiniGameRewards',
            query: {
                'brandName': brandName,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }
    /**
     * Get reward template by code
     * @param brandName
     * @param requestBody
     * @returns RandomRewardTemplateDto Get reward template by code
     * @throws ApiError
     */
    public getRewardTemplate(
        brandName: string,
        requestBody: GetRewardTemplateRequest,
    ): CancelablePromise<RandomRewardTemplateDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/GetRewardTemplate',
            query: {
                'brandName': brandName,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }
    /**
     * Get reward templates by several codes
     * @param brandName
     * @param requestBody
     * @returns GetRandomRewardTemplatesResponse Get reward templates by several codes
     * @throws ApiError
     */
    public getRewardTemplates(
        brandName: string,
        requestBody: GetRandomRewardTemplatesRequest,
    ): CancelablePromise<GetRandomRewardTemplatesResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/GetRewardTemplates',
            query: {
                'brandName': brandName,
            },
            body: requestBody,
            mediaType: '*/*',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }
}
