/* eslint-disable */
import { IServiceInstanceOptions, LastInTuple, ValidateResponse } from './types'
import { createExtensions } from './extensions'
import {
  OpenAPI,
  Api,
  FraudService,
FraudV2Service,
} from './client';
const externalServices = ['fraud','fraudV2'];

export enum Definition {
  X_MESSAGE_ID = 'xMessageId',
  X_TIMESTAMP = 'xTimestamp',
  BRAND_NAME = 'brandName',
  PLATFORM = 'platform',
  REQUEST_BODY = 'requestBody',
  X_CHK = 'xChk',
}

type ApiServiceType = Omit<FraudService & FraudV2Service , 'httpRequest'>;

type RequestBody<T extends unknown[]> = LastInTuple<T>;

type ApiServiceTypeWithRequestBody = {
  [K in keyof Omit<ApiServiceType, 'prototype'>]: (
    requestBody: RequestBody<Parameters<ApiServiceType[K]>>,
  ) => ReturnType<ApiServiceType[K]>;
};

export const createApiClient = (
  options: IServiceInstanceOptions,
): ApiServiceTypeWithRequestBody => {
  OpenAPI.BASE = options.url;
  OpenAPI.HEADERS = {
    'Content-Type': 'application/json',
    'Accept': 'application/json, text/plain, */*',
    'Access-Control-Allow-Origin': options.url || '*',
    'user-agent': options.userAgent || undefined,
    ...(options?.headers || {}),
  }
  OpenAPI.WITH_CREDENTIALS = true;
  
  OpenAPI.URL_SIGNING_KEY = options.urlSigningsKey || OpenAPI.URL_SIGNING_KEY

  const api = new Api(OpenAPI);

  if (options?.encryptedMethods) {
    OpenAPI.ENCRYPTED_METHODS = options?.encryptedMethods
  }
  
  const methodsByService = externalServices.reduce((acc, curr) => {
    // @ts-ignore need to extract all method from prototype
    const methods = Object.keys(Object.getOwnPropertyDescriptors((api[curr as keyof Api] || {})?.__proto__));
    // @ts-ignore
    methods.forEach((item) => {
      acc[item] = curr;
    })

    return acc;
  }, {} as Record<string, string>)


  let worker: Worker | null  = null;

  return new Proxy(api, {
    get(targetService, propKey: string) {
      if (methodsByService[propKey as string]) {
        const serviceKey = methodsByService[propKey as string]
        return new Proxy(function (){}, {
          apply: async (target, thisArg: unknown, argArray: unknown[]): Promise<unknown> => {
            const [requestBody] = argArray;
            const serviceExtension: string[] = Object.values(Definition);
            const extensions = createExtensions(options);
            const extensionsData = serviceExtension.map((item: string) => {
              if (item === Definition.REQUEST_BODY) {
                return requestBody;
              }

              return extensions[item].getData();
            });

            const serviceInstance = api[serviceKey as keyof Api]
            
            if (!serviceInstance) {
              console.error(serviceKey + " service does not exist in the instance")
            }

            // @ts-ignore
            const result = await serviceInstance[propKey](...extensionsData)
            
            
            
            return result
          }
        })
      }
    },
  }) as unknown as ApiServiceTypeWithRequestBody;
}
