/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type GameEventRequest = {
    location?: string;
    os?: string;
    browser?: string;
    device?: string;
    code?: string;
    name?: string;
    gameType?: string;
    currency?: string;
    scBalance?: string;
    gcBalance?: string;
    category?: string;
    country?: string;
    state?: string;
    eventType: GameEventRequest.eventType;
};
export namespace GameEventRequest {
    export enum eventType {
        OPEN_GAME = 'OPEN_GAME',
        CLOSE_GAME = 'CLOSE_GAME',
    }
}

