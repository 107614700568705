/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SpecialEventOffer } from './SpecialEventOffer';
export type OfferInfo = {
    bannerImageUrl?: string;
    code?: string;
    goldFistOffer?: number;
    goldMoney?: number;
    offerType?: OfferInfo.offerType;
    popUpImageUrl?: string;
    specialOfferUrl?: string;
    price?: number;
    oldPrice?: number;
    baseOldPrice?: number;
    currency?: string;
    basePrice?: number;
    baseCurrency?: string;
    priority?: number;
    showStickybar?: boolean;
    showTimeLeft?: boolean;
    endAt?: number;
    sweepstakeFirstOffer?: number;
    sweepstakeMoney?: number;
    tags?: Array<string>;
    title?: string;
    vipLevel?: number;
    vipPoints?: number;
    upgradeOffer?: OfferInfo;
    externalRewardCode?: string;
    specialEventOffer?: SpecialEventOffer;
};
export namespace OfferInfo {
    export enum offerType {
        ONE_TIME = 'one_time',
        SUBSCRIPTION = 'subscription',
        PERMANENT = 'permanent',
        PERSONALIZED = 'personalized',
        WEEKLY = 'weekly',
        DAILY = 'daily',
        FIXED_DAILY = 'fixed_daily',
        FIXED_WEEKLY = 'fixed_weekly',
    }
}

