/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ErrorResponse } from '../models/ErrorResponse';
import type { GetAccountNotificationsRequest } from '../models/GetAccountNotificationsRequest';
import type { GetAccountNotificationsResponse } from '../models/GetAccountNotificationsResponse';
import type { qBrandName } from '../models/qBrandName';
import type { qPlatform } from '../models/qPlatform';
import type { UpdateAccountNotificationStatusRequest } from '../models/UpdateAccountNotificationStatusRequest';
import type { UpdateAccountNotificationStatusResponse } from '../models/UpdateAccountNotificationStatusResponse';
import type { xMessageId } from '../models/xMessageId';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class NotificationService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetAccountNotificationsResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getAccountNotifications(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetAccountNotificationsRequest,
        xChk?: string,
    ): CancelablePromise<GetAccountNotificationsResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetAccountNotificationsRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns UpdateAccountNotificationStatusResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public updateAccountNotificationStatus(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: UpdateAccountNotificationStatusRequest,
        xChk?: string,
    ): CancelablePromise<UpdateAccountNotificationStatusResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/UpdateAccountNotificationStatus',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
