/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AcceptPaymentTermRequestBody } from '../models/AcceptPaymentTermRequestBody';
import type { AcceptPaymentTermResponseBody } from '../models/AcceptPaymentTermResponseBody';
import type { GetAcceptedPaymentTermsRequestBody } from '../models/GetAcceptedPaymentTermsRequestBody';
import type { GetAcceptedPaymentTermsResponseBody } from '../models/GetAcceptedPaymentTermsResponseBody';
import type { GetCryptoPaymentServiceInfoRequestBody } from '../models/GetCryptoPaymentServiceInfoRequestBody';
import type { GetCryptoPaymentServiceInfoResponseBody } from '../models/GetCryptoPaymentServiceInfoResponseBody';
import type { GetPaymentMetaInfoRequestBody } from '../models/GetPaymentMetaInfoRequestBody';
import type { GetPaymentMetaInfoResponseBody } from '../models/GetPaymentMetaInfoResponseBody';
import type { GetPaymentServiceInfoRequestBody } from '../models/GetPaymentServiceInfoRequestBody';
import type { GetPaymentServiceInfoResponseBody } from '../models/GetPaymentServiceInfoResponseBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PaymentCoreService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get basic payment settings.
     * Returns payment setting and list of enabled purchase and redeem providers.
     * @param xMessageId UUID of original request
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @returns GetCryptoPaymentServiceInfoResponseBody GetCryptoPaymentServiceInfoResponse
     * @throws ApiError
     */
    public getCryptoPaymentServiceInfo(
        xMessageId: string,
        xTimestamp: number,
        brandName: string,
        platform: 'web' | 'android' | 'ios',
        requestBody: GetCryptoPaymentServiceInfoRequestBody,
    ): CancelablePromise<GetCryptoPaymentServiceInfoResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/GetCryptoPaymentServiceInfoRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get basic payment settings.
     * Returns payment setting and list of enabled purchase and redeem providers.
     * @param xMessageId UUID of original request
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @returns GetPaymentServiceInfoResponseBody GetPaymentServiceInfoResponse
     * @throws ApiError
     */
    public getPaymentServiceInfo(
        xMessageId: string,
        xTimestamp: number,
        brandName: string,
        platform: 'web' | 'android' | 'ios',
        requestBody: GetPaymentServiceInfoRequestBody,
    ): CancelablePromise<GetPaymentServiceInfoResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/GetPaymentServiceInfoRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get basic account payment info.
     * Returns information on player purchase/redeem activity. E.G Check if player is first time purchaser.
     * @param xMessageId UUID of original request
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @returns GetPaymentMetaInfoResponseBody GetPaymentMetaInfoResponse
     * @throws ApiError
     */
    public getPaymentMetaInfo(
        xMessageId: string,
        xTimestamp: number,
        brandName: string,
        platform: 'web' | 'android' | 'ios',
        requestBody: GetPaymentMetaInfoRequestBody,
    ): CancelablePromise<GetPaymentMetaInfoResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/GetPaymentMetaInfoRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Accept payment term per account
     * Save shown payment terms per account
     * @param xMessageId UUID of original request
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @returns AcceptPaymentTermResponseBody AcceptPaymentTermResponse
     * @throws ApiError
     */
    public acceptPaymentTerm(
        xMessageId: string,
        xTimestamp: number,
        brandName: string,
        platform: 'web' | 'android' | 'ios',
        requestBody: AcceptPaymentTermRequestBody,
    ): CancelablePromise<AcceptPaymentTermResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/AcceptPaymentTermRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get accepted payment terms per account
     * Return accepted payment terms per account and the time, when they were accepted
     * @param xMessageId UUID of original request
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @returns GetAcceptedPaymentTermsResponseBody GetAcceptedPaymentTermsResponse
     * @throws ApiError
     */
    public getAcceptedPaymentTerms(
        xMessageId: string,
        xTimestamp: number,
        brandName: string,
        platform: 'web' | 'android' | 'ios',
        requestBody: GetAcceptedPaymentTermsRequestBody,
    ): CancelablePromise<GetAcceptedPaymentTermsResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/GetAcceptedPaymentTermsRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
