/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export { Api } from './Api';

export { ApiError } from './core/ApiError';
export { BaseHttpRequest } from './core/BaseHttpRequest';
export { CancelablePromise, CancelError } from './core/CancelablePromise';
export { OpenAPI } from './core/OpenAPI';
export type { OpenAPIConfig } from './core/OpenAPI';

export type { BeginPaymentMethodVerificationRequestBody } from './models/BeginPaymentMethodVerificationRequestBody';
export type { BeginPaymentMethodVerificationResponseBody } from './models/BeginPaymentMethodVerificationResponseBody';
export type { brandName } from './models/brandName';
export type { ChatDepartment } from './models/ChatDepartment';
export type { ConfirmPhoneNumberVerificationRequestBody } from './models/ConfirmPhoneNumberVerificationRequestBody';
export type { ConfirmPhoneNumberVerificationResponseBody } from './models/ConfirmPhoneNumberVerificationResponseBody';
export type { CreatePhoneNumberVerificationRequestBody } from './models/CreatePhoneNumberVerificationRequestBody';
export type { CreatePhoneNumberVerificationResponseBody } from './models/CreatePhoneNumberVerificationResponseBody';
export type { GetAccountPhoneNumberDetailsRequestBody } from './models/GetAccountPhoneNumberDetailsRequestBody';
export type { GetAccountPhoneNumberDetailsResponseBody } from './models/GetAccountPhoneNumberDetailsResponseBody';
export type { GetCardVerificationsRequestBody } from './models/GetCardVerificationsRequestBody';
export type { GetCardVerificationsResponseBody } from './models/GetCardVerificationsResponseBody';
export type { GetFraudInboxNotificationsRequestBody } from './models/GetFraudInboxNotificationsRequestBody';
export type { GetFraudInboxNotificationsResponseBody } from './models/GetFraudInboxNotificationsResponseBody';
export type { GetKYCInfoRequestBody } from './models/GetKYCInfoRequestBody';
export type { GetKYCInfoResponseBody } from './models/GetKYCInfoResponseBody';
export type { GetLiveChatSettingsRequestBody } from './models/GetLiveChatSettingsRequestBody';
export type { GetLiveChatSettingsResponseBody } from './models/GetLiveChatSettingsResponseBody';
export type { GetSkipOtpCheckRequestBody } from './models/GetSkipOtpCheckRequestBody';
export { GetSkipOtpCheckResponseBody } from './models/GetSkipOtpCheckResponseBody';
export type { GetZendeskAuthTokenRequestBody } from './models/GetZendeskAuthTokenRequestBody';
export type { GetZendeskAuthTokenResponseBody } from './models/GetZendeskAuthTokenResponseBody';
export { InboxNotification } from './models/InboxNotification';
export type { OtpModalTriggeredRequestBody } from './models/OtpModalTriggeredRequestBody';
export type { OtpModalTriggeredResponseBody } from './models/OtpModalTriggeredResponseBody';
export type { platform } from './models/platform';
export { qPlatform } from './models/qPlatform';
export { SaveChatEventRequestBody } from './models/SaveChatEventRequestBody';
export type { SaveChatEventResponseBody } from './models/SaveChatEventResponseBody';
export type { SetAccountFraudInfoRequestBody } from './models/SetAccountFraudInfoRequestBody';
export type { SetAccountFraudInfoResponseBody } from './models/SetAccountFraudInfoResponseBody';
export type { SetKYCInfoRequestBody } from './models/SetKYCInfoRequestBody';
export { SetKYCInfoResponseBody } from './models/SetKYCInfoResponseBody';
export type { SkipOtpChangeNotification } from './models/SkipOtpChangeNotification';
export { UpdateFraudInboxNotificationRequestBody } from './models/UpdateFraudInboxNotificationRequestBody';
export type { UpdateFraudInboxNotificationResponseBody } from './models/UpdateFraudInboxNotificationResponseBody';
export type { UpdateKYCInfoRequestBody } from './models/UpdateKYCInfoRequestBody';
export type { UpdateKYCInfoResponseBody } from './models/UpdateKYCInfoResponseBody';
export type { X_Chk } from './models/X_Chk';
export type { X_Message_Id } from './models/X_Message_Id';
export type { X_Timestamp } from './models/X_Timestamp';
export type { xMessageId } from './models/xMessageId';

export { FraudService } from './services/FraudService';
export { FraudV2Service } from './services/FraudV2Service';
