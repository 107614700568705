/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetKYCInfoRequestBody } from '../models/GetKYCInfoRequestBody';
import type { GetKYCInfoResponseBody } from '../models/GetKYCInfoResponseBody';
import type { GetSkipOtpCheckRequestBody } from '../models/GetSkipOtpCheckRequestBody';
import type { GetSkipOtpCheckResponseBody } from '../models/GetSkipOtpCheckResponseBody';
import type { qPlatform } from '../models/qPlatform';
import type { SetKYCInfoRequestBody } from '../models/SetKYCInfoRequestBody';
import type { SetKYCInfoResponseBody } from '../models/SetKYCInfoResponseBody';
import type { UpdateKYCInfoRequestBody } from '../models/UpdateKYCInfoRequestBody';
import type { UpdateKYCInfoResponseBody } from '../models/UpdateKYCInfoResponseBody';
import type { xMessageId } from '../models/xMessageId';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class FraudV2Service {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * GetSkipOtpCheckRequest
     * Get skip otp check request
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetSkipOtpCheckResponseBody Get skip otp check
     * @throws ApiError
     */
    public getSkipOtpCheckRequestV2(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: string,
        platform: qPlatform,
        requestBody: GetSkipOtpCheckRequestBody,
        xChk?: string,
    ): CancelablePromise<GetSkipOtpCheckResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetSkipOtpCheckRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * GetKYCInfoRequest
     * FE should call this method to get KYC info
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetKYCInfoResponseBody Updated KYC info
     * @throws ApiError
     */
    public getKycInfoRequestV2(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: string,
        platform: qPlatform,
        requestBody: GetKYCInfoRequestBody,
        xChk?: string,
    ): CancelablePromise<GetKYCInfoResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetKYCInfoRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * SetKYCInfoRequest
     * FE should call this method to update KYC info
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns SetKYCInfoResponseBody Updated KYC info
     * @throws ApiError
     */
    public setKycInfoRequestV2(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: string,
        platform: qPlatform,
        requestBody: SetKYCInfoRequestBody,
        xChk?: string,
    ): CancelablePromise<SetKYCInfoResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/SetKYCInfoRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * UpdateKYCInfoRequest
     * FE should call this method to update KYC info
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns UpdateKYCInfoResponseBody Updated KYC info
     * @throws ApiError
     */
    public updateKycInfoRequestV2(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: string,
        platform: qPlatform,
        requestBody: UpdateKYCInfoRequestBody,
        xChk?: string,
    ): CancelablePromise<UpdateKYCInfoResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/UpdateKYCInfoRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
