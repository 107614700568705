/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AcceptDailyBonusRequest } from '../models/AcceptDailyBonusRequest';
import type { AcceptDailyBonusResponse } from '../models/AcceptDailyBonusResponse';
import type { AcceptFreeGoldCoinsRequest } from '../models/AcceptFreeGoldCoinsRequest';
import type { AcceptFreeGoldCoinsResponse } from '../models/AcceptFreeGoldCoinsResponse';
import type { AcceptInvitationBonusRequest } from '../models/AcceptInvitationBonusRequest';
import type { AcceptInvitationBonusResponse } from '../models/AcceptInvitationBonusResponse';
import type { AcceptManualBonusRequest } from '../models/AcceptManualBonusRequest';
import type { AcceptManualBonusResponse } from '../models/AcceptManualBonusResponse';
import type { AcceptPersonalJackpotRequest } from '../models/AcceptPersonalJackpotRequest';
import type { AcceptPersonalJackpotResponse } from '../models/AcceptPersonalJackpotResponse';
import type { AcceptRulesRequest } from '../models/AcceptRulesRequest';
import type { AcceptRulesResponse } from '../models/AcceptRulesResponse';
import type { AcceptSocialMediaRewardRequest } from '../models/AcceptSocialMediaRewardRequest';
import type { AcceptSocialMediaRewardResponse } from '../models/AcceptSocialMediaRewardResponse';
import type { AcceptSweepstakeIntroRequest } from '../models/AcceptSweepstakeIntroRequest';
import type { AcceptSweepstakeIntroResponse } from '../models/AcceptSweepstakeIntroResponse';
import type { AccountClosureRequest } from '../models/AccountClosureRequest';
import type { AccountClosureResponse } from '../models/AccountClosureResponse';
import type { AddAccountFavouriteGameRequest } from '../models/AddAccountFavouriteGameRequest';
import type { AddAccountFavouriteGameResponse } from '../models/AddAccountFavouriteGameResponse';
import type { CancelGiveawayRequest } from '../models/CancelGiveawayRequest';
import type { CancelGiveawayResponse } from '../models/CancelGiveawayResponse';
import type { ChangePasswordRequest } from '../models/ChangePasswordRequest';
import type { ChangePasswordResponse } from '../models/ChangePasswordResponse';
import type { ChangeSubscriptionPreferencesRequest } from '../models/ChangeSubscriptionPreferencesRequest';
import type { ChangeSubscriptionPreferencesResponse } from '../models/ChangeSubscriptionPreferencesResponse';
import type { CheckLocationStatusRequest } from '../models/CheckLocationStatusRequest';
import type { CheckLocationStatusResponse } from '../models/CheckLocationStatusResponse';
import type { CheckPasswordRequest } from '../models/CheckPasswordRequest';
import type { CheckPasswordResponse } from '../models/CheckPasswordResponse';
import type { CreateAccountSessionLimitRequest } from '../models/CreateAccountSessionLimitRequest';
import type { CreateAccountSessionLimitResponse } from '../models/CreateAccountSessionLimitResponse';
import type { CreateAccountSessionRestrictionRequest } from '../models/CreateAccountSessionRestrictionRequest';
import type { CreateAccountSessionRestrictionResponse } from '../models/CreateAccountSessionRestrictionResponse';
import type { CreateCoinGiveawayRequest } from '../models/CreateCoinGiveawayRequest';
import type { CreateCoinGiveawayResponse } from '../models/CreateCoinGiveawayResponse';
import type { CreateFreeSpinGiveawayRequest } from '../models/CreateFreeSpinGiveawayRequest';
import type { CreateFreeSpinGiveawayResponse } from '../models/CreateFreeSpinGiveawayResponse';
import type { CreateGiveawayRequest } from '../models/CreateGiveawayRequest';
import type { CreateGiveawayResponse } from '../models/CreateGiveawayResponse';
import type { CreatePostalCodeRequest } from '../models/CreatePostalCodeRequest';
import type { CreatePostalCodeResponse } from '../models/CreatePostalCodeResponse';
import type { DeleteActiveAccountSessionLimitRequest } from '../models/DeleteActiveAccountSessionLimitRequest';
import type { DeleteActiveAccountSessionLimitResponse } from '../models/DeleteActiveAccountSessionLimitResponse';
import type { DoNotAllowGpsCoordinatesEventRequest } from '../models/DoNotAllowGpsCoordinatesEventRequest';
import type { DoNotAllowGpsCoordinatesEventResponse } from '../models/DoNotAllowGpsCoordinatesEventResponse';
import type { ErrorResponse } from '../models/ErrorResponse';
import type { FinalizeCoinGiveawayRequest } from '../models/FinalizeCoinGiveawayRequest';
import type { FinalizeCoinGiveawayResponse } from '../models/FinalizeCoinGiveawayResponse';
import type { FinalizeFreeSpinGiveawayRequest } from '../models/FinalizeFreeSpinGiveawayRequest';
import type { FinalizeFreeSpinGiveawayResponse } from '../models/FinalizeFreeSpinGiveawayResponse';
import type { FinalizeGiveawayRequest } from '../models/FinalizeGiveawayRequest';
import type { FinalizeGiveawayResponse } from '../models/FinalizeGiveawayResponse';
import type { GetAccountBonusesRequest } from '../models/GetAccountBonusesRequest';
import type { GetAccountBonusesResponse } from '../models/GetAccountBonusesResponse';
import type { GetAccountInfoRequest } from '../models/GetAccountInfoRequest';
import type { GetAccountInfoResponse } from '../models/GetAccountInfoResponse';
import type { GetAccountInvitationsRequest } from '../models/GetAccountInvitationsRequest';
import type { GetAccountInvitationsResponse } from '../models/GetAccountInvitationsResponse';
import type { GetAccountJackpotInfoRequest } from '../models/GetAccountJackpotInfoRequest';
import type { GetAccountJackpotInfoResponse } from '../models/GetAccountJackpotInfoResponse';
import type { GetAccountMetaRequest } from '../models/GetAccountMetaRequest';
import type { GetAccountMetaResponse } from '../models/GetAccountMetaResponse';
import type { GetAccountRefererInfoRequest } from '../models/GetAccountRefererInfoRequest';
import type { GetAccountRefererInfoResponse } from '../models/GetAccountRefererInfoResponse';
import type { GetAccountSessionLimitsRequest } from '../models/GetAccountSessionLimitsRequest';
import type { GetAccountSessionLimitsResponse } from '../models/GetAccountSessionLimitsResponse';
import type { GetAccountSessionRestrictionsRequest } from '../models/GetAccountSessionRestrictionsRequest';
import type { GetAccountSessionRestrictionsResponse } from '../models/GetAccountSessionRestrictionsResponse';
import type { GetAccountSettingsRequest } from '../models/GetAccountSettingsRequest';
import type { GetAccountSettingsResponse } from '../models/GetAccountSettingsResponse';
import type { GetActiveGiveawayByAccountRequest } from '../models/GetActiveGiveawayByAccountRequest';
import type { GetActiveGiveawayByAccountResponse } from '../models/GetActiveGiveawayByAccountResponse';
import type { GetActiveGiveawayRequest } from '../models/GetActiveGiveawayRequest';
import type { GetActiveGiveawayResponse } from '../models/GetActiveGiveawayResponse';
import type { GetBannersRequest } from '../models/GetBannersRequest';
import type { GetBannersResponse } from '../models/GetBannersResponse';
import type { GetCurrencyAvailabilityRequest } from '../models/GetCurrencyAvailabilityRequest';
import type { GetCurrencyAvailabilityResponse } from '../models/GetCurrencyAvailabilityResponse';
import type { GetFreeGoldCoinsStatusRequest } from '../models/GetFreeGoldCoinsStatusRequest';
import type { GetFreeGoldCoinsStatusResponse } from '../models/GetFreeGoldCoinsStatusResponse';
import type { GetGiveawayRequest } from '../models/GetGiveawayRequest';
import type { GetGiveawayResponse } from '../models/GetGiveawayResponse';
import type { GetGiveawaySubscribersNumberRequest } from '../models/GetGiveawaySubscribersNumberRequest';
import type { GetGiveawaySubscribersNumberResponse } from '../models/GetGiveawaySubscribersNumberResponse';
import type { GetInfluencerInfoRequest } from '../models/GetInfluencerInfoRequest';
import type { GetInfluencerInfoResponse } from '../models/GetInfluencerInfoResponse';
import type { GetJackpotInfoRequest } from '../models/GetJackpotInfoRequest';
import type { GetJackpotInfoResponse } from '../models/GetJackpotInfoResponse';
import type { GetJackpotOptInRequest } from '../models/GetJackpotOptInRequest';
import type { GetJackpotOptInResponse } from '../models/GetJackpotOptInResponse';
import type { GetJackpotTotalsRequest } from '../models/GetJackpotTotalsRequest';
import type { GetJackpotTotalsResponse } from '../models/GetJackpotTotalsResponse';
import type { GetLotteryResultsRequest } from '../models/GetLotteryResultsRequest';
import type { GetLotteryResultsResponse } from '../models/GetLotteryResultsResponse';
import type { GetPersonalJackpotRequest } from '../models/GetPersonalJackpotRequest';
import type { GetPersonalJackpotResponse } from '../models/GetPersonalJackpotResponse';
import type { GetSignupCountriesAndStatesRequest } from '../models/GetSignupCountriesAndStatesRequest';
import type { GetSignupCountriesAndStatesResponse } from '../models/GetSignupCountriesAndStatesResponse';
import type { GetSignupCountriesRequest } from '../models/GetSignupCountriesRequest';
import type { GetSignupCountriesResponse } from '../models/GetSignupCountriesResponse';
import type { GetTemporaryTokenRequest } from '../models/GetTemporaryTokenRequest';
import type { GetTemporaryTokenResponse } from '../models/GetTemporaryTokenResponse';
import type { GetVisitedPromotionsRequest } from '../models/GetVisitedPromotionsRequest';
import type { GetVisitedPromotionsResponse } from '../models/GetVisitedPromotionsResponse';
import type { LowOnCoinsRequest } from '../models/LowOnCoinsRequest';
import type { LowOnCoinsResponse } from '../models/LowOnCoinsResponse';
import type { MissionStepUpdateRequest } from '../models/MissionStepUpdateRequest';
import type { MissionStepUpdateResponse } from '../models/MissionStepUpdateResponse';
import type { OptInGiveawayRequest } from '../models/OptInGiveawayRequest';
import type { OptInGiveawayResponse } from '../models/OptInGiveawayResponse';
import type { OptInPromoRequest } from '../models/OptInPromoRequest';
import type { OptInPromoResponse } from '../models/OptInPromoResponse';
import type { OptOutArbitrationRequest } from '../models/OptOutArbitrationRequest';
import type { OptOutArbitrationResponse } from '../models/OptOutArbitrationResponse';
import type { qAppName } from '../models/qAppName';
import type { qBrandName } from '../models/qBrandName';
import type { qPlatform } from '../models/qPlatform';
import type { RemoveAccountFavouriteGameRequest } from '../models/RemoveAccountFavouriteGameRequest';
import type { RemoveAccountFavouriteGameResponse } from '../models/RemoveAccountFavouriteGameResponse';
import type { ResendEmailVerificationRequest } from '../models/ResendEmailVerificationRequest';
import type { ResendEmailVerificationResponse } from '../models/ResendEmailVerificationResponse';
import type { ResetPasswordManuallyRequest } from '../models/ResetPasswordManuallyRequest';
import type { ResetPasswordManuallyResponse } from '../models/ResetPasswordManuallyResponse';
import type { SaveJackpotOptInRequest } from '../models/SaveJackpotOptInRequest';
import type { SaveJackpotOptInResponse } from '../models/SaveJackpotOptInResponse';
import type { SendSignInByTokenEmailRequest } from '../models/SendSignInByTokenEmailRequest';
import type { SendSignInByTokenEmailResponse } from '../models/SendSignInByTokenEmailResponse';
import type { SendWelcomeEmailRequest } from '../models/SendWelcomeEmailRequest';
import type { SendWelcomeEmailResponse } from '../models/SendWelcomeEmailResponse';
import type { SetAccountAttributionInfoRequest } from '../models/SetAccountAttributionInfoRequest';
import type { SetAccountAttributionInfoResponse } from '../models/SetAccountAttributionInfoResponse';
import type { SetAccountCommunicationPreferencesRequest } from '../models/SetAccountCommunicationPreferencesRequest';
import type { SetAccountCommunicationPreferencesResponse } from '../models/SetAccountCommunicationPreferencesResponse';
import type { SetAccountInfoRequest } from '../models/SetAccountInfoRequest';
import type { SetAccountInfoResponse } from '../models/SetAccountInfoResponse';
import type { SetAccountMarketingInfoRequest } from '../models/SetAccountMarketingInfoRequest';
import type { SetAccountMarketingInfoResponse } from '../models/SetAccountMarketingInfoResponse';
import type { SetAccountModeRequest } from '../models/SetAccountModeRequest';
import type { SetAccountModeResponse } from '../models/SetAccountModeResponse';
import type { SetAccountOnboardedFlagRequest } from '../models/SetAccountOnboardedFlagRequest';
import type { SetAccountOnboardedFlagResponse } from '../models/SetAccountOnboardedFlagResponse';
import type { SetLocationInfoRequest } from '../models/SetLocationInfoRequest';
import type { SetLocationInfoResponse } from '../models/SetLocationInfoResponse';
import type { SetPushInfoRequest } from '../models/SetPushInfoRequest';
import type { SetPushInfoResponse } from '../models/SetPushInfoResponse';
import type { SpinWheelOfWinnersRequest } from '../models/SpinWheelOfWinnersRequest';
import type { SpinWheelOfWinnersResponse } from '../models/SpinWheelOfWinnersResponse';
import type { UpdateAccountSessionLimitRequest } from '../models/UpdateAccountSessionLimitRequest';
import type { UpdateAccountSessionLimitResponse } from '../models/UpdateAccountSessionLimitResponse';
import type { UpdateLocationStatusRequest } from '../models/UpdateLocationStatusRequest';
import type { UpdateLocationStatusResponse } from '../models/UpdateLocationStatusResponse';
import type { UpdateVisitedPromotionsRequest } from '../models/UpdateVisitedPromotionsRequest';
import type { UpdateVisitedPromotionsResponse } from '../models/UpdateVisitedPromotionsResponse';
import type { xCoordinates } from '../models/xCoordinates';
import type { xMessageId } from '../models/xMessageId';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CrmService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns AcceptSocialMediaRewardResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public acceptAcceptSocialMediaRewardRequest(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: AcceptSocialMediaRewardRequest,
        xChk?: string,
    ): CancelablePromise<AcceptSocialMediaRewardResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/AcceptSocialMediaRewardRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @param appName Native App Identifier
     * @returns AcceptDailyBonusResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public acceptDailyBonus(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: AcceptDailyBonusRequest,
        xChk?: string,
        appName?: qAppName,
    ): CancelablePromise<AcceptDailyBonusResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/AcceptDailyBonusRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
                'appName': appName,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns AcceptFreeGoldCoinsResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public acceptFreeGoldCoins(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: AcceptFreeGoldCoinsRequest,
        xChk?: string,
    ): CancelablePromise<AcceptFreeGoldCoinsResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/AcceptFreeGoldCoinsRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @param appName Native App Identifier
     * @returns AcceptInvitationBonusResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public acceptInvitationBonus(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: AcceptInvitationBonusRequest,
        xChk?: string,
        appName?: qAppName,
    ): CancelablePromise<AcceptInvitationBonusResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/AcceptInvitationBonusRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
                'appName': appName,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @param appName Native App Identifier
     * @returns AcceptManualBonusResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public acceptManualBonus(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: AcceptManualBonusRequest,
        xChk?: string,
        appName?: qAppName,
    ): CancelablePromise<AcceptManualBonusResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/AcceptManualBonusRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
                'appName': appName,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @param appName Native App Identifier
     * @returns AcceptRulesResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public acceptRules(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: AcceptRulesRequest,
        xChk?: string,
        appName?: qAppName,
    ): CancelablePromise<AcceptRulesResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/AcceptRulesRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
                'appName': appName,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns AcceptSweepstakeIntroResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public acceptSweepstakeIntro(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: AcceptSweepstakeIntroRequest,
        xChk?: string,
    ): CancelablePromise<AcceptSweepstakeIntroResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/AcceptSweepstakeIntroRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns AddAccountFavouriteGameResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public addAccountFavouriteGame(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: AddAccountFavouriteGameRequest,
        xChk?: string,
    ): CancelablePromise<AddAccountFavouriteGameResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/AddAccountFavouriteGameRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns CancelGiveawayResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public cancelGiveawayRequest(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: CancelGiveawayRequest,
        xChk?: string,
    ): CancelablePromise<CancelGiveawayResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/CancelGiveawayRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns ChangePasswordResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public changePassword(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: ChangePasswordRequest,
        xChk?: string,
    ): CancelablePromise<ChangePasswordResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/ChangePasswordRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns ChangeSubscriptionPreferencesResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public changeSubscriptionPreferences(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: ChangeSubscriptionPreferencesRequest,
        xChk?: string,
    ): CancelablePromise<ChangeSubscriptionPreferencesResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/ChangeSubscriptionPreferencesRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @param appName Native App Identifier
     * @returns CheckLocationStatusResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public checkLocationStatus(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: CheckLocationStatusRequest,
        xChk?: string,
        appName?: qAppName,
    ): CancelablePromise<CheckLocationStatusResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/CheckLocationStatusRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
                'appName': appName,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns CheckPasswordResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public checkPassword(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: CheckPasswordRequest,
        xChk?: string,
    ): CancelablePromise<CheckPasswordResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/CheckPasswordRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns AccountClosureResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public closeAccount(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: AccountClosureRequest,
        xChk?: string,
    ): CancelablePromise<AccountClosureResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/AccountClosureRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns CreateAccountSessionLimitResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public createAccountSessionLimit(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: CreateAccountSessionLimitRequest,
        xChk?: string,
    ): CancelablePromise<CreateAccountSessionLimitResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/CreateAccountSessionLimitRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns CreateAccountSessionRestrictionResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public createAccountSessionRestriction(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: CreateAccountSessionRestrictionRequest,
        xChk?: string,
    ): CancelablePromise<CreateAccountSessionRestrictionResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/CreateAccountSessionRestrictionRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns CreateCoinGiveawayResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public createCoinGiveawayRequest(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: CreateCoinGiveawayRequest,
        xChk?: string,
    ): CancelablePromise<CreateCoinGiveawayResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/CreateCoinGiveawayRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns CreateFreeSpinGiveawayResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public createFreeSpinGiveawayRequest(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: CreateFreeSpinGiveawayRequest,
        xChk?: string,
    ): CancelablePromise<CreateFreeSpinGiveawayResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/CreateFreeSpinGiveawayRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns CreateGiveawayResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public createGiveawayRequest(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: CreateGiveawayRequest,
        xChk?: string,
    ): CancelablePromise<CreateGiveawayResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/CreateGiveawayRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns CreatePostalCodeResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public createPostalCode(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: CreatePostalCodeRequest,
        xChk?: string,
    ): CancelablePromise<CreatePostalCodeResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/CreatePostalCodeRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns DeleteActiveAccountSessionLimitResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public deleteActiveAccountSessionLimit(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: DeleteActiveAccountSessionLimitRequest,
        xChk?: string,
    ): CancelablePromise<DeleteActiveAccountSessionLimitResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/DeleteActiveAccountSessionLimitRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @param appName Native App Identifier
     * @returns DoNotAllowGpsCoordinatesEventResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public doNotAllowGpsCoordinatesEvent(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: DoNotAllowGpsCoordinatesEventRequest,
        xChk?: string,
        appName?: qAppName,
    ): CancelablePromise<DoNotAllowGpsCoordinatesEventResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/DoNotAllowGpsCoordinatesEventRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
                'appName': appName,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns FinalizeCoinGiveawayResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public finalizeCoinGiveawayRequest(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: FinalizeCoinGiveawayRequest,
        xChk?: string,
    ): CancelablePromise<FinalizeCoinGiveawayResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/FinalizeCoinGiveawayRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns FinalizeFreeSpinGiveawayResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public finalizeFreeSpinGiveawayRequest(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: FinalizeFreeSpinGiveawayRequest,
        xChk?: string,
    ): CancelablePromise<FinalizeFreeSpinGiveawayResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/FinalizeFreeSpinGiveawayRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns FinalizeGiveawayResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public finalizeGiveawayRequest(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: FinalizeGiveawayRequest,
        xChk?: string,
    ): CancelablePromise<FinalizeGiveawayResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/FinalizeGiveawayRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @param appName Native App Identifier
     * @returns GetAccountBonusesResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getAccountBonuses(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetAccountBonusesRequest,
        xChk?: string,
        appName?: qAppName,
    ): CancelablePromise<GetAccountBonusesResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetAccountBonusesRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
                'appName': appName,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @param appName Native App Identifier
     * @returns GetAccountInfoResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getAccountInfo(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetAccountInfoRequest,
        xChk?: string,
        appName?: qAppName,
    ): CancelablePromise<GetAccountInfoResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetAccountInfoRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
                'appName': appName,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetAccountInvitationsResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getAccountInvitations(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetAccountInvitationsRequest,
        xChk?: string,
    ): CancelablePromise<GetAccountInvitationsResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetAccountInvitationsRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetAccountMetaResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getAccountMeta(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetAccountMetaRequest,
        xChk?: string,
    ): CancelablePromise<GetAccountMetaResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetAccountMetaRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetAccountRefererInfoResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getAccountReferrer(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetAccountRefererInfoRequest,
        xChk?: string,
    ): CancelablePromise<GetAccountRefererInfoResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetAccountRefererInfoRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetAccountSessionLimitsResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getAccountSessionLimits(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetAccountSessionLimitsRequest,
        xChk?: string,
    ): CancelablePromise<GetAccountSessionLimitsResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetAccountSessionLimitsRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetAccountSessionRestrictionsResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getAccountSessionRestrictions(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetAccountSessionRestrictionsRequest,
        xChk?: string,
    ): CancelablePromise<GetAccountSessionRestrictionsResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetAccountSessionRestrictionsRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetAccountSettingsResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getAccountSettings(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetAccountSettingsRequest,
        xChk?: string,
    ): CancelablePromise<GetAccountSettingsResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetAccountSettingsRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetActiveGiveawayByAccountResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getActiveGiveawayByAccountRequest(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetActiveGiveawayByAccountRequest,
        xChk?: string,
    ): CancelablePromise<GetActiveGiveawayByAccountResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetActiveGiveawayByAccountRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetActiveGiveawayResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getActiveGiveawayRequest(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetActiveGiveawayRequest,
        xChk?: string,
    ): CancelablePromise<GetActiveGiveawayResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetActiveGiveawayRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetBannersResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getBanners(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetBannersRequest,
        xChk?: string,
    ): CancelablePromise<GetBannersResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetBannersRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetCurrencyAvailabilityResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getCurrencyAvailabilityRequest(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetCurrencyAvailabilityRequest,
        xChk?: string,
    ): CancelablePromise<GetCurrencyAvailabilityResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetCurrencyAvailabilityRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetFreeGoldCoinsStatusResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getFreeGoldCoinsStatus(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetFreeGoldCoinsStatusRequest,
        xChk?: string,
    ): CancelablePromise<GetFreeGoldCoinsStatusResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetFreeGoldCoinsStatusRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetGiveawayResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getGiveawayRequest(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetGiveawayRequest,
        xChk?: string,
    ): CancelablePromise<GetGiveawayResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetGiveawayRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetGiveawaySubscribersNumberResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getGiveawaySubscribersNumberRequest(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetGiveawaySubscribersNumberRequest,
        xChk?: string,
    ): CancelablePromise<GetGiveawaySubscribersNumberResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetGiveawaySubscribersNumberRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetInfluencerInfoResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getInfluencerInfoRequest(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetInfluencerInfoRequest,
        xChk?: string,
    ): CancelablePromise<GetInfluencerInfoResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetInfluencerInfoRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetLotteryResultsResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getLotteryResults(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetLotteryResultsRequest,
        xChk?: string,
    ): CancelablePromise<GetLotteryResultsResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetLotteryResultsRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetSignupCountriesResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getSignupCountries(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetSignupCountriesRequest,
        xChk?: string,
    ): CancelablePromise<GetSignupCountriesResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetSignupCountriesRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetSignupCountriesAndStatesResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getSignupCountriesAndStates(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetSignupCountriesAndStatesRequest,
        xChk?: string,
    ): CancelablePromise<GetSignupCountriesAndStatesResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetSignupCountriesAndStatesRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @param appName Native App Identifier
     * @returns GetTemporaryTokenResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getTemporaryToken(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetTemporaryTokenRequest,
        xChk?: string,
        appName?: qAppName,
    ): CancelablePromise<GetTemporaryTokenResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetTemporaryTokenRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
                'appName': appName,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetVisitedPromotionsResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getVisitedPromotions(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetVisitedPromotionsRequest,
        xChk?: string,
    ): CancelablePromise<GetVisitedPromotionsResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetVisitedPromotionsRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns LowOnCoinsResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public lowOnCoins(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: LowOnCoinsRequest,
        xChk?: string,
    ): CancelablePromise<LowOnCoinsResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/LowOnCoinsRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns MissionStepUpdateResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public missionStepUpdateRequest(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: MissionStepUpdateRequest,
        xChk?: string,
    ): CancelablePromise<MissionStepUpdateResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/MissionStepUpdateRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns OptInGiveawayResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public optInGiveaway(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: OptInGiveawayRequest,
        xChk?: string,
    ): CancelablePromise<OptInGiveawayResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/OptInGiveawayRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns OptInPromoResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public optInPromo(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: OptInPromoRequest,
        xChk?: string,
    ): CancelablePromise<OptInPromoResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/OptInPromoRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns OptOutArbitrationResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public optOutArbitration(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: OptOutArbitrationRequest,
        xChk?: string,
    ): CancelablePromise<OptOutArbitrationResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/OptOutArbitrationRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns RemoveAccountFavouriteGameResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public removeAccountFavouriteGame(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: RemoveAccountFavouriteGameRequest,
        xChk?: string,
    ): CancelablePromise<RemoveAccountFavouriteGameResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/RemoveAccountFavouriteGameRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @param appName Native App Identifier
     * @returns ResendEmailVerificationResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public resendEmailVerification(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: ResendEmailVerificationRequest,
        xChk?: string,
        appName?: qAppName,
    ): CancelablePromise<ResendEmailVerificationResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/ResendEmailVerificationRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
                'appName': appName,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns ResetPasswordManuallyResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public resetPasswordManually(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: ResetPasswordManuallyRequest,
        xChk?: string,
    ): CancelablePromise<ResetPasswordManuallyResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/ResetPasswordManuallyRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns SendSignInByTokenEmailResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public sendSignInByTokenEmail(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: SendSignInByTokenEmailRequest,
        xChk?: string,
    ): CancelablePromise<SendSignInByTokenEmailResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/SendSignInByTokenEmailRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns SendWelcomeEmailResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public sendWelcomeEmail(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: SendWelcomeEmailRequest,
        xChk?: string,
    ): CancelablePromise<SendWelcomeEmailResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/SendWelcomeEmailRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns SetAccountAttributionInfoResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public setAccountAttributionInfo(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: SetAccountAttributionInfoRequest,
        xChk?: string,
    ): CancelablePromise<SetAccountAttributionInfoResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/SetAccountAttributionInfoRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns SetAccountCommunicationPreferencesResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public setAccountCommunicationPreferences(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: SetAccountCommunicationPreferencesRequest,
        xChk?: string,
    ): CancelablePromise<SetAccountCommunicationPreferencesResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/SetAccountCommunicationPreferencesRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns SetAccountInfoResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public setAccountInfo(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: SetAccountInfoRequest,
        xChk?: string,
    ): CancelablePromise<SetAccountInfoResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/SetAccountInfoRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns SetAccountMarketingInfoResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public setAccountMarketingInfo(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: SetAccountMarketingInfoRequest,
        xChk?: string,
    ): CancelablePromise<SetAccountMarketingInfoResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/SetAccountMarketingInfoRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns SetAccountModeResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public setAccountMode(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: SetAccountModeRequest,
        xChk?: string,
    ): CancelablePromise<SetAccountModeResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/SetAccountModeRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns SetAccountOnboardedFlagResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public setAccountOnboardedFlag(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: SetAccountOnboardedFlagRequest,
        xChk?: string,
    ): CancelablePromise<SetAccountOnboardedFlagResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/SetAccountOnboardedFlagRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns SetLocationInfoResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public setLocationInfo(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: SetLocationInfoRequest,
        xChk?: string,
    ): CancelablePromise<SetLocationInfoResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/SetLocationInfoRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns SetPushInfoResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public setPushInfo(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: SetPushInfoRequest,
        xChk?: string,
    ): CancelablePromise<SetPushInfoResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/SetPushInfoRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns SpinWheelOfWinnersResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public spinWheelOfWinners(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: SpinWheelOfWinnersRequest,
        xChk?: string,
    ): CancelablePromise<SpinWheelOfWinnersResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/SpinWheelOfWinnersRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns UpdateAccountSessionLimitResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public updateAccountSessionLimit(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: UpdateAccountSessionLimitRequest,
        xChk?: string,
    ): CancelablePromise<UpdateAccountSessionLimitResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/UpdateAccountSessionLimitRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update account location status with IP location and GPS coordinates
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param xClientCoordinates Geolocation of client (latitude, longitude)
     * @param xChk Enable additional request validation (anything or empty)
     * @param requestBody
     * @returns UpdateLocationStatusResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public updateLocationStatus(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        xClientCoordinates?: xCoordinates,
        xChk?: string,
        requestBody?: UpdateLocationStatusRequest,
    ): CancelablePromise<UpdateLocationStatusResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/UpdateLocationStatusRequest',
            headers: {
                'X-Client-Coordinates': xClientCoordinates,
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns UpdateVisitedPromotionsResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public updateVisitedPromotions(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: UpdateVisitedPromotionsRequest,
        xChk?: string,
    ): CancelablePromise<UpdateVisitedPromotionsResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/UpdateVisitedPromotionsRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns AcceptPersonalJackpotResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public acceptPersonalJackpot(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: AcceptPersonalJackpotRequest,
        xChk?: string,
    ): CancelablePromise<AcceptPersonalJackpotResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/AcceptPersonalJackpotRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetAccountJackpotInfoResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getAccountJackpotInfo(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetAccountJackpotInfoRequest,
        xChk?: string,
    ): CancelablePromise<GetAccountJackpotInfoResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetAccountJackpotInfoRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetJackpotInfoResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getJackpotInfo(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetJackpotInfoRequest,
        xChk?: string,
    ): CancelablePromise<GetJackpotInfoResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetJackpotInfoRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @deprecated
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetJackpotOptInResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getJackpotOptIn(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetJackpotOptInRequest,
        xChk?: string,
    ): CancelablePromise<GetJackpotOptInResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetJackpotOptInRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @deprecated
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetJackpotTotalsResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getJackpotTotals(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetJackpotTotalsRequest,
        xChk?: string,
    ): CancelablePromise<GetJackpotTotalsResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetJackpotTotalsRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @deprecated
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetPersonalJackpotResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getPersonalJackpot(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetPersonalJackpotRequest,
        xChk?: string,
    ): CancelablePromise<GetPersonalJackpotResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetPersonalJackpotRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns SaveJackpotOptInResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public saveJackpotOptIn(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: SaveJackpotOptInRequest,
        xChk?: string,
    ): CancelablePromise<SaveJackpotOptInResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/SaveJackpotOptInRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
